import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import Select from 'react-select';

const QuantityPriceInput = ({ onPriceChange, currency, initialData = [] }) => {
  const [ranges, setRanges] = useState([{ min: '', max: '', price: '', discountPrice: '', metric: '' }]);

  useEffect(() => {
    if (initialData && initialData.length > 0) {
      setRanges(initialData);
    }
  }, [initialData]);


  const options = [
    { value: 'kilogram', label: 'Kg' },
    { value: 'litre', label: 'Litres' },
    { value: 'piece', label: 'Pieces' },
    { value: 'gram', label: 'Grams' },
    { value: 'millilitre', label: 'Millilitres' },
    { value: 'ton', label: 'Tons' },
    { value: 'cubic_meter', label: 'Cubic Meters' },
    { value: 'cubic_centimeter', label: 'Cubic Centimeters' },
    { value: 'cubic_feet', label: 'Cubic Feet' },
    { value: 'pound', label: 'Pounds' },
    { value: 'ounce', label: 'Ounces' },
    { value: 'gallon', label: 'Gallons' },
    { value: 'quart', label: 'Quarts' },
    { value: 'barrel', label: 'Barrels' },
    { value: 'bundle', label: 'Bundles' },
    { value: 'dozen', label: 'Dozens' },
    { value: 'box', label: 'Boxes' },
    { value: 'pallet', label: 'Pallets' },
  ];

  const handleRangeChange = (index, field, value) => {
    const newRanges = ranges.map((range, i) => {
      if (i === index) {
        return { ...range, [field]: value }; // Create a new object for the updated range
      }
      return range;
    });
    setRanges(newRanges);
    onPriceChange(newRanges);
  };

  const handleChange = (selectedOption, index) => {
    const newRanges = ranges.map((range, i) => {
      if (i === index) {
        return { ...range, metric: selectedOption.value };
      }
      return range;
    });
    setRanges(newRanges);
    onPriceChange(newRanges);
  };

  const addRange = () => {
    const newRange = { min: '', max: '', price: '', discountPrice: '', metric: '' };
    setRanges([...ranges, newRange]);
    onPriceChange([...ranges, newRange]);
  };

  const removeRange = (index) => {
    const newRanges = ranges.filter((_, i) => i !== index);
    setRanges(newRanges);
    onPriceChange(newRanges);
  };

  return (
    <div className="border border-gray-300 rounded-lg p-6 shadow-lg bg-white space-y-4">
      {ranges.map((range, index) => (
        <div key={index} className="flex flex-wrap items-center space-x-2">
          <Select
            options={options}
            value={options.find((option) => option.value === range.metric)}
            onChange={(selectedOption) => handleChange(selectedOption, index)}
            className="w-full sm:w-1/6 min-w-[80px]"
            placeholder="Metric"
          />
          <input
            type="number"
            value={range.min}
            onChange={(e) => handleRangeChange(index, 'min', e.target.value)}
            placeholder="Min Qty"
            className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
          />
          <span className="text-gray-500 mx-1">to</span>
          <input
            type="number"
            value={range.max}
            onChange={(e) => handleRangeChange(index, 'max', e.target.value)}
            placeholder="Max Qty"
            className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
          />
          <span className="text-green-600 font-semibold mx-1">@ {currency}</span>
          <input
            type="number"
            value={range.price}
            onChange={(e) => handleRangeChange(index, 'price', e.target.value)}
            placeholder="Price"
            className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
          />
          <input
            type="number"
            value={range.discountPrice}
            onChange={(e) => handleRangeChange(index, 'discountPrice', e.target.value)}
            placeholder="Discount Price"
            className="w-full sm:w-1/6 min-w-[80px] px-2 py-1 border rounded"
          />
          <button
            type="button"
            onClick={() => removeRange(index)}
            className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600"
          >
            <FaTrash />
          </button>
          {index === ranges.length - 1 && (
            <button
              type="button"
              onClick={addRange}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
            >
              <FaPlus />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuantityPriceInput;
