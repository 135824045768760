import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import logo from "../../Assests/images/logoo.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import CountrySelect from '../CountrySelect';
import { FaUser, FaUserTie } from "react-icons/fa";


const ShopCreate = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState();
  const [location, setLocation] = useState();
  const [workingHours, setWorkingHours] = useState(null);
  const [avatar, setAvatar] = useState();
  const [banner, setBanner] = useState();
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [accountType, setAccountType] = useState('individual');
  const [isChecked, setIsChecked] = useState(false);



  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLocation(latLng);
      setAddress(address);
    
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(`${server}/shop/create-shop`, {
        name,
        email,
        password,
        avatar,
        banner,
        phoneNumber,
        address,
        location,
        workingHours,
        country,
        accountType,
      })
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        setAvatar();
        setBanner();
        setCountry();
        setWorkingHours();
        setLocation();
        setAddress("");
        setPhoneNumber();
        setAccountType();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
  
    // Supported file types for logo images
    const validImageTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"];
    const maxFileSize = 2 * 1024 * 1024; // 2 MB
  
    if (file) {
      // Validate file type
      if (!validImageTypes.includes(file.type)) {
        toast.error("Invalid file format. Please upload a JPG, JPEG, PNG, or SVG image.");
        return;
      }
  
      // Validate file size
      if (file.size > maxFileSize) {
        toast.error("File size exceeds 2 MB. Please upload a smaller image.");
        return;
      }
  
      const reader = new FileReader();
  
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatar(reader.result); // Set the image preview
        }
      };
  
      reader.readAsDataURL(file);
    }
  };
  


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  return (
    <div className="min-h-screen bg-gradient-to-r from-orange-400 to-white-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-23 w-auto" src={logo} alt="Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
          Register as a seller
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Shop Name
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>



            <div>
              <label
                htmlFor="accountType"
                className="block text-sm font-medium text-gray-700"
              >
                Account Type
              </label>
              <div className="mt-1 flex space-x-4">
                <div
                  onClick={() => setAccountType('individual')}
                  className={`flex items-center justify-center p-2 border rounded-md cursor-pointer ${accountType === 'individual' ? 'border-blue-500 bg-blue-300' : 'border-gray-300 bg-white'
                    } transition-colors duration-200 ease-in-out`}
                >
                  <FaUser className="mr-2 text-xl" />
                  <span className="text-sm font-medium text-gray-700">Individual</span>
                </div>
                <div
                  onClick={() => setAccountType('business')}
                  className={`flex items-center justify-center p-2 border rounded-md cursor-pointer ${accountType === 'business' ? 'border-blue-500 bg-blue-300' : 'border-gray-300 bg-white'
                    } transition-colors duration-200 ease-in-out`}
                >
                  <FaUserTie className="mr-2 text-xl" />
                  <span className="text-sm font-medium text-gray-700">Business</span>
                </div>
              </div>
            </div>

            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <PhoneInput
                  country={'ke'}
                  value={phoneNumber}
                  onChange={phone => setPhoneNumber(phone)}
                  inputClass="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address<span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            {/* <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Address
              </label>
              <div className="mt-1">
                <input
                  type="address"
                  name="address"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div> */}

            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address <span className="text-red-500">*</span></label>
              <div className="mt-1">
                <PlacesAutocomplete
                  value={address}
                  onChange={(address) => setAddress(address)}
                  onSelect={handleSelect}
                  searchOptions={{
                    types: [`address`],
                    componentRestrictions: {
                      country: 'ke',
                      // administrativeArea: '',
                      // locality: '',
                      //strictBounds: true
                    }

                  }}
                  className="flex-grow"
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="flex-grow">
                      <input
                        {...getInputProps({
                          placeholder: 'Enter your address',
                          className: 'input w-full',
                        })}
                      />
                      <div>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                            cursor: 'pointer',
                            padding: '0.5rem',
                          };
                          return (
                            <div {...getSuggestionItemProps(suggestion, { style })}>
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              {addressError && <p className="text-red-500">{addressError}</p>}
            </div>


            <div>
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                {/* <input
                  type="number"
                  name="country"
                  required
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                /> */}
                <CountrySelect defaultCountry="Kenya" setCountry={setCountry} country={country} />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700"
              ></label>
              <div className="mt-2 flex items-center">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="avatar"
                      className="h-full w-full object-cover rounded-full"
                    />
                  ) : (
                    <RxAvatar className="h-8 w-8" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <span>Upload Logo/Avatar</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    onChange={handleFileInputChange}
                    className="sr-only"
                  />
                </label>
              </div>
            </div>

            <div className="flex items-center my-4">
              <input
                type="checkbox"
                id="terms"
                className="mr-2"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="terms" className="text-gray-700">
                I agree to the{' '}
                <a
                  href="/vendor-terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  terms and conditions
                </a>
              </label>
            </div>

            <div>
              <button
                type="submit"
                className={`group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md ${isChecked ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                disabled={!isChecked}
              >
                Submit
              </button>
            </div>
            <div className={`${styles.noramlFlex} w-full`}>
              <h4>Already have an account?</h4>
              <Link to="/shop-login" className="text-blue-600 pl-2">
                Sign in
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopCreate;
